import React, { lazy, Suspense, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { createRoot } from "react-dom/client";

import ErrorBoundary from "./utils/ErrorBoundaryComponent";

const lazyPortals = {
  AffiliatesPage: lazy(() => import("./portals/AffiliatesPage")),
  NewsLetterSubscribe: lazy(() => import("./portals/NewsLetterSubscribe")),
  NavBarSearch: lazy(() => import("./portals/NavBarSearch")),
  HomeSearch: lazy(() => import("./portals/HomeSearch")),
  JoinUsPage: lazy(() => import("./portals/JoinUsPage")),
  RecruitingPage: lazy(() => import("./portals/RecruitingPage")),
  SearchPage: lazy(() => import("./portals/SearchPage")),
  SearchMap: lazy(() => import("./portals/SearchMap")),
  AgentListings: lazy(() => import("./portals/AgentListings")),
  RENews: lazy(() => import("./portals/RENews")),
  AgentRegistrationAdmin: lazy(
    () => import("./portals/AgentRegistrationAdmin"),
  ),
  AgentRegistrationPublic: lazy(
    () => import("./portals/AgentRegistrationPublic"),
  ),
  NestYachts: lazy(() => import("./portals/NestYachts")),
  AdminReact: lazy(() => import("./portals/AdminReact")),
  UserMenu: lazy(() => import("./portals/UserMenu")),
};

type Portal = {
  name: string;
  component: React.LazyExoticComponent<React.ComponentType<any>>;
  container: Element;
  props: Record<string, string | number | null>;
};
type Portals = Record<string, Portal>;

/**
 * PortalLoader is a component that will lazy load components and render them as a portal
 * for each element with the attribute data-react-portal
 *
 * It does this when this is first mounted on the page.
 */
function PortalLoader() {
  // on mount detect any data-react-portal,
  // lazy load those components
  // and render them in portals
  const [portals, setPortals] = useState<Portals>({});

  useEffect(() => {
    document.querySelectorAll("[data-react-portal]").forEach((el) => {
      const name = el.getAttribute("data-react-portal");
      const props = el.getAttribute("data-props");
      const page = lazyPortals[name];
      if (!page) {
        if (process.env.NODE_ENV === "development") {
          throw Error(`portal not registered: ${name}`);
        }
        console.error("portal not registered", name, lazyPortals);
        return;
      }
      setPortals((prev) => ({
        ...prev,
        [name]: {
          name,
          component: page,
          container: el,
          props: props ? JSON.parse(props) : {},
        },
      }));
    });
  }, []);

  return (
    <div id="portal-loader">
      {Object.values(portals).map((portal) => {
        const inner = (
          <Suspense fallback={null} key={portal.name}>
            <portal.component {...portal.props} />
          </Suspense>
        );

        return createPortal(
          <ErrorBoundary>{inner}</ErrorBoundary>,
          portal.container,
        );
      })}
    </div>
  );
}

const container = document.getElementById("portal-loader");
// Probably due to the hot loader this is called multiple times
const didMount = !!container.getAttribute("data-did-mount");

if (!didMount) {
  const root = createRoot(container);
  root.render(<PortalLoader />);
  container.setAttribute("data-did-mount", "true");
}
