import React from "react";
import ErrorMessage from "../components/ui/ErrorMessage";
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "/react/components/shadcn/ui/alert";

// import { ExclamationTriangleIcon } from "@radix-ui/react-icons";

import { getNinjaAPI } from "/react/api/publicAPI";

const { logError } = getNinjaAPI();

const defaultPageClass =
  "userMenuContent content-full flex flex-col content-full flex flex-col";

interface Props {
  children: React.ReactNode;
  className?: string;
  silent?: boolean;
}

const initialState = {
  error: null,
  errorInfo: null,
};

/**
 * This must be a Component class because it uses componentDidCatch
 */
export default class ErrorBoundary extends React.Component<Props> {
  public state = initialState;

  public componentDidCatch(err, info) {
    this.setState({
      error: err,
      errorInfo: info,
    });
    this.logErrorToAPI(err, info);
  }

  public logErrorToAPI(error: Error, errorInfo) {
    logError({
      url: window.location.href,
      error: error.message,
      errorInfo,
    });
  }

  public render() {
    if (this.state.errorInfo) {
      if (this.props.silent) {
        console.error(this.state.error);
        return null;
      }

      return (
        <div className={this.props.className ?? defaultPageClass}>
          <Alert className="max-w-6xl mt-4 py-8 px-4 font-sans border-red-100">
            <div className="flex flex-row gap-2 items-center">
              {/* <ExclamationTriangleIcon className="h-8 w-8" /> */}
              <AlertTitle className="text-xl">
                You have encountered an error!
              </AlertTitle>
            </div>
            <AlertDescription>
              <ErrorMessage>{this.state.error.toString()}</ErrorMessage>
              <details
                open={process.env.NODE_ENV !== "production"}
                className="mx-8"
              >
                <div
                  style={{
                    whiteSpace: "pre-wrap",
                    padding: "0 2rem",
                  }}
                >
                  {this.state.errorInfo.componentStack}
                </div>
              </details>
            </AlertDescription>
          </Alert>
        </div>
      );
    }

    return this.props.children;
  }
}
