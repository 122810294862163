/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * NinjaAPI
 * Nest Seekers public backend-for-frontend API
 * OpenAPI spec version: 1.0.0
 */
import axios from "axios";
import type { AxiosRequestConfig, AxiosResponse } from "axios";
import type { LogErrorPayload } from "./publicModels";

export const getNinjaAPI = () => {
  /**
   * Frontend exceptions are posted here and logged.
   * @summary Log Frontend Error
   */
  const logError = <TData = AxiosResponse<string>>(
    logErrorPayload: LogErrorPayload,
    options?: AxiosRequestConfig,
  ): Promise<TData> => {
    return axios.post(`/api/public/public/log-error`, logErrorPayload, options);
  };

  return { logError };
};
export type LogErrorResult = AxiosResponse<string>;
