import React from "react";

export default function ErrorMessage({ children }) {
  return (
    <div className="text-center center m-2">
      <i
        className="fa fa-exclamation-triangle text-red-500"
        aria-hidden="true"
      />
      <span className="ml-2 text-base text-red-500">{children}</span>
    </div>
  );
}

/**
 * For use in react-hook-form with the ErrorMessage component:
 *
 *  import { ErrorMessage } from "@hookform/error-message";
 *  // ... inside the hook
 *  <ErrorMessage errors={errors} name="is_new_license" render={renderErrorMessage} />
 */
export function renderErrorMessage({ message }: { message: string }) {
  return <ErrorMessage>{message}</ErrorMessage>;
}
